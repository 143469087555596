import React from "react";
import NumberTicker from "../../utility/NumberTicker";
import { LineChartShad } from '../charts/LineChart';
import { Skeleton } from "../../utility/Skeleton";
import { useCard } from "../context/cardContext";

const GraphMetric = () => {
    const { dataMetrics, status, loading } = useCard();

    const getBorderClass = (status) => {
        switch (status) {
            case 'warning':
                return 'border-2 border-yellow-500'; // Orange border for warning
            case 'error':
                return 'border-2 border-red-600'; // Red border for error
            default:
                return 'border border-[#E3E5E8]'; // Default border for no status
        }
    };

    return (
        <a
            href={dataMetrics.link}
            className={`rounded-xl bg-white shadow-lg p-4 flex flex-col sm:flex-row gap-4 grow select-none h-full max-w-full ${getBorderClass(status)}`}
        >   
        <div className="flex-shrink-0 select-none">
            {!loading && dataMetrics.count ?
            <h2 className="text-lg font-semibold my-2">{dataMetrics.mainTitle}</h2>
            : <Skeleton className="w-36 h-10 mb-2" />
            }
            <h1 className={`text-2xl font-bold select-none ${dataMetrics.count ? 'text-black' : 'text-gray-400'}`}>
                {!loading && dataMetrics.count ? <NumberTicker
                    from={0}
                    target={dataMetrics.count}
                    autoStart={true}
                    transition={{ duration: 2.5, type: "tween", ease: "easeInOut" }}
                /> : <div className="flex flex-row">
                    <Skeleton className="w-10 h-8 mr-2" />
                </div>}
            </h1>
        </div>
        {!loading && dataMetrics.lineData ? (
            <LineChartShad chartData={!loading && dataMetrics.lineData} />
        ) : (
            <div className="flex items-center justify-center">
                <Skeleton className="w-44 h-28 md:w-72 md:h-52" />
            </div>
        )}
        </a>
    );
};

export default GraphMetric;