import React from 'react';
import { useMetrics } from '../context/MetricsContext';
import { Skeleton } from '../../utility/Skeleton';

const GroupedCard = ({ title, cards }) => {
    const { loading } = useMetrics();
    return (
        <div className="w-full p-6 border border-[#E3E5E8] bg-[#FFF59D] rounded-xl shadow-lg my-4 overflow-hidden">
            {loading ? (
                <Skeleton className="w-36 h-10 mb-4" />
            ) : (
                title && <h2 className="text-xl font-bold mb-4">{title}</h2>
            )}
            <div className="flex flex-wrap gap-4 min-w-28">
                {cards.map((card, index) => (
                    <div key={index} className="min-w-28 grow">
                        {card.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupedCard;
