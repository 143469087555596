import React, { createContext, useContext, useState, useEffect } from "react";
import { getData } from "../config/metricData";

// Create the context
const MetricsContext = createContext();

// Custom hook to use the context easily
export const useMetrics = () => useContext(MetricsContext);


// IMPORTANT: Creating a card
// 1. Add a variable to the metrics state
// 2. Add a function to set the variable in the fetchData function

// Provider component
export const MetricsProvider = ({ children }) => {
    const [metrics, setMetrics] = useState({
        orgs: { active: null, total: null },
        spotCount: null,
        vehiclesTracked: null,
        cameraCount: { overhead: null, lpr: null },
        userCount: { total: null, nav: null, enforcement: null, admin: null },
        totalUsersGraphData: { count: null, lineData: null, options: null },
        lotRequestCount: null,
        downloadQRScans: null,
        lots: { active: null, total: null },
        imagesSaved: null,
        ovhSize: null,
        ovhProcessing: null,
        ovhIteration: null,
        ovhSendOccupancy: null,
        cameraStatus: null,
        sentryStatus: null,
        platesRead: { validated: null, nonValidated: null },
        violations: { total: null, citations: null },
        avgLprTime: null,
        avgVideoSize: null,
        avgVideoProcessingTime: null,
        appDownloads: null,
        activeAppUsers: null,
        appCrashes: null,
        revenue: null,
        expenses: null,
        thresholds: {},
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getData({
                setOrgs: (data) => setMetrics((prev) => ({ ...prev, orgs: data })),
                setSpotCount: (data) => setMetrics((prev) => ({ ...prev, spotCount: data })),
                setVehiclesTracked: (data) => setMetrics((prev) => ({ ...prev, vehiclesTracked: data })),
                setCameraCount: (data) => setMetrics((prev) => ({ ...prev, cameraCount: data })),
                setUserCount: (data) => setMetrics((prev) => ({ ...prev, userCount: data })),
                setTotalUsersGraphData: (data) => setMetrics((prev) => ({ ...prev, totalUsersGraphData: data })),
                setLotRequestCount: (data) => setMetrics((prev) => ({ ...prev, lotRequestCount: data })),
                setDownloadQRScans: (data) => setMetrics((prev) => ({ ...prev, downloadQRScans: data })),
                setLots: (data) => setMetrics((prev) => ({ ...prev, lots: data })),
                setImagesSaved: (data) => setMetrics((prev) => ({ ...prev, imagesSaved: data })),
                setOvhSize: (data) => setMetrics((prev) => ({ ...prev, ovhSize: data })),
                setOvhProcessing: (data) => setMetrics((prev) => ({ ...prev, ovhProcessing: data })),
                setOvhIteration: (data) => setMetrics((prev) => ({ ...prev, ovhIteration: data })),
                setOvhSendOccupancy: (data) => setMetrics((prev) => ({ ...prev, ovhSendOccupancy: data })),
                setCameraStatus: (data) => setMetrics((prev) => ({ ...prev, cameraStatus: data })),
                setSentryStatus: (data) => setMetrics((prev) => ({ ...prev, sentryStatus: data })),
                setThresholds: (data) => setMetrics((prev) => ({ ...prev, thresholds: data })),
                setPlatesRead: (data) => setMetrics((prev) => ({ ...prev, platesRead: data })),
                setViolations: (data) => setMetrics((prev) => ({ ...prev, violations: data })),
                setAvgLprTime: (data) => setMetrics((prev) => ({ ...prev, avgLprTime: data })),
                setAvgVideoSize: (data) => setMetrics((prev) => ({ ...prev, avgVideoSize: data })),
                setAvgVideoProcessingTime: (data) => setMetrics((prev) => ({ ...prev, avgVideoProcessingTime: data })),
                setAppDownloads: (data) => setMetrics((prev) => ({ ...prev, appDownloads: data })),
                setActiveAppUsers: (data) => setMetrics((prev) => ({ ...prev, activeAppUsers: data })),
                setAppCrashes: (data) => setMetrics((prev) => ({ ...prev, appCrashes: data })),
                setRevenue: (data) => setMetrics((prev) => ({ ...prev, revenue: data })),
                setExpenses: (data) => setMetrics((prev) => ({ ...prev, expenses: data })),
            });
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <MetricsContext.Provider value={{ metrics, setMetrics, loading }}>
            {children}
        </MetricsContext.Provider>
    );
};