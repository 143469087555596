import React from "react";
import { CardProvider } from "../../metricCards/context/cardContext";
import useGroupedMetrics from "../../metricCards/customHooks/useGroupedMetrics";
import GroupCard from "../../metricCards/cards/GroupCard";
import HealthUtils from "../../metricCards/health/health";
import HealthIndicator from "../../metricCards/health/HealthIndicator";
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

//TODO Eventually implement https://dndkit.com/


// IMPORTANT: This component is the main entry point for the reports page. 

// IMPORTANT: Creating a Card
// The three files you need to create a new card are:
// 1. MetricsContext.js - This file contains the context for the metrics data.
// 2. cardConfig.js - This file contains the configuration for the cards.
// 3. metricData.js - This file contains the logic for retrieving the data for the cards.
// Those files will have more information on how to create a card.

export const CompanyMetrics = () => {
    const { groupedData, setThresholds, loading, healthPercent } = useGroupedMetrics();

    return (
        <div className="ml-20 p-8">
            <div className="flex flex-row justify-between items-center mb-4">
                <h1 className="text-3xl font-bold select-none">Reports</h1>
                {!loading && ( <HealthIndicator healthPercent={healthPercent} /> )}
            </div>
            {Object.entries(groupedData).map(([group, cards], index) => (
                <GroupCard
                    key={index}
                    title={group}
                    cards={cards.map(({ component, props }, cardIndex) => {
                        const CardComponent = require(`../../metricCards/cards/${component}`).default;

                        const status = HealthUtils.getStatus({ ...props });

                        return {
                            content:
                            <CardProvider key={cardIndex} defaultData={props} setThresholds={setThresholds} status={status} loading={loading}>
                                <CardComponent />
                            </CardProvider>,
                        };
                    })}
                />
            ))}
        </div>
    );
};

export default CompanyMetrics;