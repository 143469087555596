import React from "react";
import { CartesianGrid, LabelList, Line, LineChart, XAxis } from "recharts";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./chart";

export function LineChartShad({ chartData }) {
  const chartConfig = {
    users: {
      label: "users",
      color: "hsl(var(--chart-1))",
    },
  };

  // Aggregate counts by month and year and filter out zero counts
  const aggregatedData = Object.values(
    chartData.reduce((acc, entry) => {
      if (entry.count === 0) return acc; // Skip entries with count = 0

      const date = new Date(entry.date);
      const monthYear = date.toLocaleDateString("default", {
        month: "short",
        year: "numeric",
      }); // Format as "Aug 2023"

      if (!acc[monthYear]) {
        acc[monthYear] = { month: monthYear, users: 0 };
      }

      acc[monthYear].users += entry.count; // Sum up counts for the same month-year
      return acc;
    }, {})
  );

  return (
    <ChartContainer config={chartConfig}>
      <LineChart
        accessibilityLayer
        data={aggregatedData}
        margin={{
          top: 20,
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="line" />}
        />
        <Line
          dataKey="users"
          type="natural"
          stroke="var(--color-users)"
          strokeWidth={2}
          dot={{
            fill: "var(--color-users)",
          }}
          activeDot={{
            r: 6,
          }}
        >
          <LabelList
            position="top"
            offset={12}
            className="fill-foreground"
            fontSize={12}
          />
        </Line>
      </LineChart>
    </ChartContainer>
  );
}
