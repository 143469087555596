import React, { useEffect, useState } from 'react';
import BaseMetricCard from './BaseMetricCard';
import { LuListFilter } from "react-icons/lu";

const LogsCard = () => {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        // Simulating a fetch request with a timeout
        setTimeout(() => {
            // Example log structure (replace with actual API data when available)
            setLogs([
                { id: 1, timestamp: '2025-01-02 14:30:45', message: 'User logged in', level: 'info' },
                { id: 2, timestamp: '2025-01-02 14:32:10', message: 'Database updated', level: 'warning' },
                { id: 3, timestamp: '2025-01-02 14:35:20', message: 'Server restarted', level: 'critical' },
            ]);
            setIsLoading(false);
        }, 1000);
    }, []);

    const levelColorMap = {
        critical: 'text-red-600',
        warning: 'text-yellow-600',
        info: 'text-green-600',
    };

    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const filteredLogs = selectedFilter === 'all' ? logs : logs.filter(log => log.level === selectedFilter);

    return (
        <BaseMetricCard mainTitle={null} link={null}>
            <div className="relative flex flex-col mb-3">
                <div className="absolute top-0 right-0">
                    <button
                        onClick={toggleDropdown}
                        className="p-2 rounded hover:bg-gray-100"
                        aria-label="Toggle filters"
                    >
                        <LuListFilter className="w-5 h-5" />
                    </button>

                    {showDropdown && (
                        <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg z-20">
                            {['all', 'info', 'warning', 'critical'].map((filter) => (
                                <button
                                    key={filter}
                                    className={`block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 ${
                                        selectedFilter === filter ? "font-bold" : ""
                                    }`}
                                    onClick={() => {
                                        setSelectedFilter(filter);
                                        setShowDropdown(false);
                                    }}
                                >
                                    {filter === 'all' ? 'All Levels' : filter.charAt(0).toUpperCase() + filter.slice(1)}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <a href={null} target="_blank" rel="noopener noreferrer">
                <h1 className="text-lg font-semibold mb-2">Recent Logs</h1>
            </a>
            {isLoading ? (
                <p className="text-gray-500">Loading logs...</p>
            ) : filteredLogs.length === 0 ? (
                <p className="text-gray-500">No logs available</p>
            ) : (
                <ul className="overflow-y-auto divide-y divide-gray-200">
                    {filteredLogs.map((log) => (
                        <li key={log.id} className="py-2">
                            <div className="flex justify-between items-center">
                                <span className={`text-sm font-medium ${levelColorMap[log.level] || 'text-gray-800'}`}>{log.message}</span>
                                <span className="text-xs text-gray-400 pl-1">{log.timestamp}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </BaseMetricCard>
    );
};

export default LogsCard;