import { useMetrics } from "../context/MetricsContext";
import { cardData } from "../config/cardConfig";
import HealthUtils from "../health/health";

const useGroupedMetrics = () => {
    const { metrics, setMetrics, loading } = useMetrics();

    const setThresholds = (newThresholds) => {
        setMetrics((prevMetrics) => ({
            ...prevMetrics,
            thresholds: newThresholds(prevMetrics.thresholds),
        }));
    }

    const links = { 
        usersTable: 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=spotUsers', 
        orgsTable: 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=organizations', 
        spotsTable: 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=000004-spots', 
        spotMetricsTable:'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=spotMetrics' 
    };

    const groupedData = cardData({...metrics, links}).reduce((acc, card) => {
        const { group } = card;
        if (!acc[group]) acc[group] = [];
        acc[group].push(card);
        return acc;
    }, {});

    const allCards = Object.values(groupedData).flat();
    const filteredCards = allCards.filter(({ props: { settingName } }) => metrics.thresholds[settingName]?.error !== undefined && metrics.thresholds[settingName]?.warning !== undefined);
    const healthPercent = filteredCards.length > 0 ? HealthUtils.calcHealthPercent(filteredCards) : 100;

    return { groupedData, setThresholds, loading, healthPercent };
};

export default useGroupedMetrics;