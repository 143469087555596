import React from "react";
import { Link } from "react-router-dom";

export const CmsHome = () => {
    return (
        <div className="pl-32 p-12">
            <h1 className="font-bold font-rubik text-6xl">CMS Home</h1>
            <ul className="mt-8">
                <li>
                    <Link to="/cms/api" className="underline">Manage API Docs</Link>
                </li>
                <li>
                    <Link to="/cms/videos" className="underline">Manage Videos</Link>
                </li>
            </ul>
        </div>
    );
}; 