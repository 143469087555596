import { useState } from "react";
import { useCard } from "../context/cardContext";
import { useMediaQuery } from "@react-hook/media-query";
import { LuInfo } from "react-icons/lu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogClose,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "../../utility/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from "../../utility/drawer";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const ThresholdEdit = () => {
  const { dataMetrics, setThresholds, status } = useCard();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const initialWarning = dataMetrics.warning;
  const initialError = dataMetrics.error;
  const [warning, setWarning] = useState(initialWarning);
  const [error, setError] = useState(initialError);
  const [savedLoading, setSavedLoading] = useState(false);

  const updateThresholds = async () => {
    if (!warning || !error) {
        alert("Please enter both warning and error thresholds.");
        return;
    }

    const requestBody = {
        settingName: dataMetrics.settingName,
        settingData: {
            warning: parseInt(warning),
            error: parseInt(error),
        },
    };

    try {
        setSavedLoading(true);
        const response = await fetch("https://api.spotparking.app/SpotAPI/general/godPortalSettings", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_METRIC_API_KEY,
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        setWarning(requestBody.settingData.warning);
        setError(requestBody.settingData.error);

        setThresholds((prev) => ({
            ...prev,
            [dataMetrics.settingName]: {
                warning: warning,
                error: error,
            },
        }));
    } catch (error) {
        console.error("Error updating thresholds:", error);
        alert("Failed to update thresholds.");
    } finally {
        setSavedLoading(false);
    }
  };

  const renderForm = () => (
    <div className="w-full flex flex-col gap-4">
      <div className="flex items-center gap-2 w-full">
        <label htmlFor="warningThreshold" className="text-sm font-medium w-1/3">
          Warning
        </label>
        <input
          type="number"
          id="warningThreshold"
          value={warning}
          onChange={(e) => setWarning(e.target.value)}
          className="w-2/3 px-4 py-2 mt-0 border rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none"
          autoFocus={false}
        />
      </div>
      <div className="flex items-center gap-2 w-full">
        <label htmlFor="errorThreshold" className="text-sm font-medium w-1/3">
          Error
        </label>
        <input
          type="number"
          id="errorThreshold"
          value={error}
          onChange={(e) => setError(e.target.value)}
          className="w-2/3 px-4 py-2 mt-0 border rounded-lg focus:ring-2 focus:ring-red-600 outline-none"
          autoFocus={false}
        />
      </div>
    </div>
  );

  if (isDesktop)
    return (
      <Dialog>
        <DialogTrigger asChild>
          <button>
            <LuInfo className="absolute bottom-2 right-2 text-gray-500 cursor-pointer" />
          </button>
        </DialogTrigger>
        <DialogContent>
          <div className="mx-auto w-full max-w-sm flex flex-col items-start gap-4 text-left">
            <div className="w-full">
              <DialogHeader>
                <DialogTitle>{dataMetrics.mainTitle}</DialogTitle>
                <DialogDescription>Current Status: {capitalize(status)}</DialogDescription>
                <DialogDescription>Update Status Threshold Settings</DialogDescription>
              </DialogHeader>
            </div>
            {renderForm()}
            <DialogFooter className="flex flex-col w-full gap-2">
              <div className="flex flex-row items-center justify-between w-full gap-2">
                <DialogClose className="w-full py-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition duration-200">
                  Close
                </DialogClose>
                <button
                  onClick={updateThresholds}
                  className="w-full py-2 bg-blue-400 text-white rounded-lg hover:bg-blue-500 transition duration-200"
                  disabled={savedLoading}
                >
                  {savedLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    );

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <button>
          <LuInfo className="absolute bottom-2 right-2 text-gray-500 cursor-pointer" />
        </button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm flex flex-col items-start gap-4 text-left">
          <div className="w-full">
            <DrawerHeader>
              <DrawerTitle>{dataMetrics.mainTitle}</DrawerTitle>
              <DrawerDescription>Current Status: {capitalize(status)}</DrawerDescription>
              <DrawerDescription>Update Status Threshold Settings</DrawerDescription>
            </DrawerHeader>
          </div>
          {renderForm()}
          <DrawerFooter className="flex flex-col w-full gap-2">
            <div className="flex flex-row items-center justify-between w-full gap-2">
              <DrawerClose className="w-full py-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition duration-200">
                Close
              </DrawerClose>
              <button
                onClick={updateThresholds}
                className="w-full py-2 bg-blue-400 text-white rounded-lg hover:bg-blue-500 transition duration-200"
                disabled={savedLoading}
              >
                {savedLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default ThresholdEdit;
