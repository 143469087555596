import { Label, PolarRadiusAxis, RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./chart";

export function Radial({ title, chartData }) {
  const chartKeys = Object.keys(chartData[0] || {}).filter(
    (key) => key !== "label"
  );

  const chartConfig = chartKeys.map((key, index) => ({
    dataKey: key,
    color: `hsl(var(--chart-${index + 1}))`,
  }));

  return (
    <ChartContainer
      config={chartConfig}
      className="mx-auto aspect-square w-full max-w-[250px]"
    >
      <ResponsiveContainer>
        <RadialBarChart
          className="px-4"
          data={chartData}
          endAngle={180}
          innerRadius={80}
          outerRadius={130}
        >
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                hideLabel
                className="w-[180px]"
                formatter={(value, name, item, index) => (
                  <>
                    <div
                      className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                      style={
                        {
                          "--color-bg": `var(--color-${index})`,
                        }
                      }
                    />
                    {name}
                    <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                      {value}
                    </div>
                    {index === chartKeys.length - 1 && (
                      <div className="mt-1.5 flex basis-full items-center border-t pt-1.5 text-xs font-medium text-foreground">
                        Total
                        <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                          {chartKeys.reduce((sum, key) => sum + (item.payload[key] || 0), 0)}
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            }
            dataKeyToLabelMap={{
              [chartConfig[0].dataKey]: title,
              "total": "Other",
            }}
            title={title}
          />
          <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  return (
                    <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) - 16}
                        className="fill-foreground text-2xl font-bold"
                      >
                        {chartData[0][chartConfig[0].dataKey]}
                      </tspan>
                      {chartData[0][chartConfig[0].dataKey] !== 0 && (
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 4}
                          className="fill-muted-foreground text-base"
                        >
                          {title}
                        </tspan>
                      )}
                    </text>
                  );
                }
              }}
            />
          </PolarRadiusAxis>
          {chartConfig.map((config, index) => (
            <RadialBar
              key={config.dataKey}
              dataKey={config.dataKey}
              stackId="a"
              cornerRadius={5}
              fill={config.color}
              className="stroke-transparent stroke-2"
            />
          ))}
        </RadialBarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
