import React from 'react';
import NumberTicker from '../../utility/NumberTicker';
import BaseMetricCard from './BaseMetricCard';
import ThresholdEdit from '../config/thresholdEdit';
import { Skeleton } from '../../utility/Skeleton';
import { useCard } from '../context/cardContext';

const SimpleMetricCard = () => {
  const { dataMetrics, loading } = useCard();
  return (
    <div className="relative w-full h-full">
      <BaseMetricCard mainTitle={dataMetrics.mainTitle} className="h-full">
        <div className="flex flex-col justify-between gap-2 h-full select-none">
          <div className="flex flex-row items-center gap-2 select-none">
            <h1 className={"text-2xl font-bold text-black"}>
              {!loading && (dataMetrics.data1 || dataMetrics.data1 === 0) ? (
                <NumberTicker
                  from={0}
                  target={dataMetrics.data1}
                  autoStart={true}
                  transition={{ duration: 2.5, type: "tween", ease: "easeInOut" }}
                />
              ) : (
                <>
                  <Skeleton className="w-36 h-10 mb-2" />
                  <Skeleton className="w-10 h-8" />
                </>
              )}
            </h1>
            <h1 className="text-sm text-gray-500 select-none">
              {!loading ? dataMetrics.subTitle1 : ""}
            </h1>
          </div>
        </div>
      </BaseMetricCard>
      {!loading && (dataMetrics.data1 || dataMetrics.data1 === 0) && dataMetrics.warning && dataMetrics.error && (
        <ThresholdEdit />
      )}
    </div>
  );
};

export default SimpleMetricCard;
