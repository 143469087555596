import React from "react";
import GeneralSentrySettings from "./components/pages/SentrySettings/GeneralSettings";
import SingleCamSetting from "./components/pages/SentrySettings/SingleCamSetting";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Directory } from "./components/pages/universities/Directory";
import { UniPage } from "./components/pages/universities/UniPage";
import Statistics from "./components/pages/statistics/Statistics";
import { MyAccount } from "./components/pages/settings/MyAccount";
import ApiConsole from "./components/pages/apiConsole/ApiConsole";
import { Users } from "./components/pages/Users";
import ResetPassword from "./components/pages/ResetPassword";
import Announcements from "./components/pages/Announcements";
import MapView from "./components/pages/mapView/MapView";
import Sidebar from "./components/sidebar/Sidebar";
import { Signup } from "./components/pages/SignUp";
import { Login } from "./components/pages/Login";
import Cookies from "js-cookie";
import "./styles.css";
import CompanyMetrics from "./components/pages/reports/CompanyMetrics";
import Billing from "./components/pages/reports/Billing";
import APICalls from "./components/pages/reports/APICalls";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Onboarding from "./components/pages/Onboarding";
import { CmsHome } from "./components/pages/cms/CmsHome";
import ApiDocsEditor from "./components/pages/cms/ApiDocsEditor";
import { VideosEditor } from "./components/pages/cms/VideosEditor";
import { MetricsProvider } from "./components/metricCards/context/MetricsContext";

export default function App() {
    const jsCookie = Cookies.get("token");
    const allowedPaths = ["/login", "/contact-us", "/reset-temp-password", "/reset-password"];

    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
    } else {
        console.log("Authenticated:", false);
        sessionStorage.clear();
        Cookies.remove("token");

        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
        }
    }

    return (
        <GoogleOAuthProvider clientId="403367791110-0ohc25riqv9o3tqtepvlvajgkpqms8ha.apps.googleusercontent.com">
            <Router>
                {!allowedPaths.includes(window.location.pathname) && <Sidebar />}
                <Routes>
                    {/* Main Pages */}
                    <Route path="/" element={
                    <MetricsProvider>
                        <CompanyMetrics />
                    </MetricsProvider>
                } />
                    <Route path="/directory" element={<Directory />} />
                    <Route path="/directory/university" element={<UniPage />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/api-console" element={<ApiConsole />} />
                    <Route path="/map" element={<MapView />} />
                    <Route path="/announcements" element={<Announcements />} />
                    {/* Website */}
                    <Route path="/Reports/company-metrics" element={<CompanyMetrics />} />
                    {/* <Route path="/billing" element={<Billing />} /> */}
                    {/* <Route path="/onboarding" element={<Onboarding />} /> */}
                    <Route path="/users" element={<Users />} />
                    <Route path="/Reports/api-calls" element={<APICalls />} />
                    <Route path="/sentries" element={<GeneralSentrySettings />} />
                    <Route path="/sentries/:sentry" element={<SingleCamSetting />} />
                    {/* CMS */}
                    <Route path="/cms" element={<CmsHome />} />
                    <Route path="/cms/api" element={<ApiDocsEditor />} />
                    <Route path="/cms/videos" element={<VideosEditor />} />
                    {/* Settings */}
                    <Route path="/settings/myaccount" element={<MyAccount />} />
                    <Route path="/settings/users" element={<Users />} />
                    {/* Login Stuff */}
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
            </Router>
        </GoogleOAuthProvider>
    );
}