import React, { useState } from "react";
import NumberTicker from "../../utility/NumberTicker";
import BaseMetricCard from "./BaseMetricCard";
import ThresholdEdit from "../config/thresholdEdit";
import { Radial } from "../charts/Radial";
import { LuListFilter } from "react-icons/lu";
import { Skeleton } from '../../utility/Skeleton';
import { useCard } from "../context/cardContext";

const FilterableMetricCard = () => {
    const { dataMetrics, loading } = useCard();
    const [selectedFilter, setSelectedFilter] = useState(dataMetrics.filters[0]);
    const [showDropdown, setShowDropdown] = useState(false);

    const filteredData = dataMetrics.data[selectedFilter];
    const total = dataMetrics.data["Total"];

    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const chartData = Array.isArray(filteredData)
    ? filteredData.map((item) => ({
        data: item.value,
        label: item.key,
      }))
    : [{ data: filteredData, total: total - filteredData, label: selectedFilter }];

    return (
        <div className="relative w-full h-full">
            <BaseMetricCard>
                <div className="relative flex flex-col">
                    <div className="absolute top-0 right-0">
                        {!loading && filteredData !== null && filteredData !== undefined ? (
                            <button
                                onClick={toggleDropdown}
                                className="p-2 rounded hover:bg-gray-100"
                                aria-label="Toggle filters"
                            >
                                <LuListFilter className="w-5 h-5" />
                            </button>
                        ) : (
                            <Skeleton className="w-8 h-8" />
                        )}

                        {showDropdown && (
                            <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg z-10">
                                {dataMetrics.filters.map((filter) => (
                                    <button
                                        key={filter}
                                        className={`block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 ${
                                            selectedFilter === filter ? "font-bold" : ""
                                        }`}
                                        onClick={() => {
                                            setSelectedFilter(filter);
                                            setShowDropdown(false);
                                        }}
                                    >
                                        {filter}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col max-h-52">
                        <a href={dataMetrics.link} target="_blank" rel="noopener noreferrer">
                            {!loading && filteredData !== null && filteredData !== undefined ? (
                                <h1 className="text-lg font-semibold mb-2">{dataMetrics.mainTitle}</h1>
                            ) : (
                                <Skeleton className="w-36 h-10 mb-2" />
                            )}
                        </a>
                        <div className="flex flex-row items-center gap-2">
                            {!loading && dataMetrics.prefix && (
                                <h1 className="text-2xl font-bold text-black">{dataMetrics.prefix}</h1>
                            )}
                            <h1
                                className={`text-2xl font-bold ${
                                    filteredData !== null && filteredData !== undefined ? "text-black" : "text-gray-400"
                                }`}
                                >
                                {!loading && filteredData !== null && filteredData !== undefined ? (
                                    !dataMetrics.radial && (
                                    <NumberTicker
                                        key={filteredData}
                                        from={0}
                                        target={filteredData}
                                        autoStart={true}
                                        transition={{ duration: 1, type: "tween", ease: "easeInOut" }}
                                    />
                                    )
                                ) : (
                                    <div className="flex items-center justify-center">
                                        <Skeleton className="w-44 h-40 md:w-64 md:h-40" />
                                    </div>

                                )}
                            </h1>

                            {!loading && filteredData !== null && filteredData !== undefined && (
                                !dataMetrics.radial && (
                                    <h1 className="text-sm text-gray-500">{`${selectedFilter}`}</h1>
                                )
                            )}
                        </div>
                        {!loading && dataMetrics.radial && (
                            <Radial title={selectedFilter} chartData={chartData}/>
                        )}
                    </div>
                </div>
            </BaseMetricCard>
            {!loading && filteredData !== null && filteredData !== undefined && dataMetrics.warning && dataMetrics.error &&  (
                <ThresholdEdit />
            )}
        </div>
    );
};

export default FilterableMetricCard;