import React, { createContext, useContext, useEffect, useState } from 'react';

const CardContext = createContext();

export const useCard = () => useContext(CardContext);

export const CardProvider = ({ children, defaultData = {}, setThresholds, status, loading }) => {
  const [dataMetrics, setDataMetrics] = useState({
    ...defaultData,
    mainTitle: null,
    subTitle1: null,
    data1: null,
    subTitle2: null,
    data2: null,
    link: null,
    settingName: null,
    warning: null,
    error: null,
    reverse: null,
  });

  useEffect(() => {
    setDataMetrics(defaultData);
  }, [defaultData]);

  return (
    <CardContext.Provider value={{dataMetrics, setDataMetrics, setThresholds, status, loading}}>
      {children}
    </CardContext.Provider>
  );
};