import React from 'react';
import { useCard } from '../context/cardContext';

const BaseMetricCard = ({ children, mainTitle }) => {
    const { dataMetrics, status, loading } = useCard();

    const getBorderClass = (status) => {
        switch (status) {
            case 'warning':
                return 'border-2 border-yellow-500'; // Orange border for warning
            case 'error':
                return 'border-2 border-red-600'; // Red border for error
            default:
                return 'border border-[#E3E5E8]'; // Default border for no status
        }
    };

    return (
        <div
            className={`bg-white rounded-xl p-4 h-full grow select-none shadow-lg mb-2 ${getBorderClass(
                status
            )}`}
        >
            {dataMetrics.mainTitle !== null && dataMetrics.mainTitle !== undefined && (
                <a href={dataMetrics.link} target="_blank" rel="noopener noreferrer">
                    <h2 className="text-lg font-semibold my-2">{!loading ? mainTitle : ""}</h2>
                </a>
            )}
            {children}
        </div>
    );
};

export default BaseMetricCard;