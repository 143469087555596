import React, { useState, useEffect, useRef } from "react";
import { RiArrowDropRightLine } from "react-icons/ri";
import TextareaAutosize from "react-textarea-autosize";
import { FaTrashAlt } from "react-icons/fa";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function ApiDocsEditor() {
    const [docs, setDocs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [toggledEndpoints, setToggledEndpoints] = useState({});
    const endpointsRef = useRef({});
    const methodsRef = useRef({});
    const parameterTitleRef = useRef({});
    const parameterDescriptionRef = useRef({});
    const errorCodeRef = useRef({});

    useEffect(() => {
        async function fetchDocs() {
            try {
                const response = await fetch("/docs.json");
                if (!response.ok) throw new Error("Failed to fetch documentation");
                const data = await response.json();
                setDocs(data.apiDocs);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        fetchDocs();
    }, []);

    const toggleEndpoint = (endpoint) => {
        setToggledEndpoints((prev) => ({
            ...prev,
            [endpoint]: !prev[endpoint],
        }));
    };

    const saveToS3 = async () => {
        console.log(JSON.stringify(docs, null, 2));
        // if (!docs) return;
        // try {
        //     const response = await fetch("https://your-s3-api-endpoint.com/upload", {
        //         method: "PUT",
        //         headers: {
        //             "Content-Type": "application/json",
        //         },
        //         body: JSON.stringify({ apiDocs: docs }),
        //     });

        //     if (!response.ok) throw new Error("Failed to save documentation");

        //     alert("Documentation saved successfully!");
        // } catch (err) {
        //     alert(`Error saving documentation: ${err.message}`);
        // }
    };

    const addNewEndpoint = (newEndpointTitle) => {
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [newEndpointTitle]: {},
            },
        }));
    };

    const deleteEndpoint = (endpoint) => {
        const newEndpoints = { ...docs.endpoints };
        delete newEndpoints[endpoint];
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: newEndpoints,
        }));

        setDeleteModalOpen(false);
        setEndpointToDelete(null);
    };

    const addNewMethod = (endpoint, newMethodTitle) => {
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: {
                    ...prevDocs.endpoints[endpoint],
                    [newMethodTitle]: {
                        "description": "",
                        "parameters": {},
                        "response": {
                            "status": 200,
                            "example": {},
                        },
                    },
                },
            },
        }));
    };

    const deleteMethod = (endpoint, method) => {
        const newMethods = { ...docs.endpoints[endpoint] };
        delete newMethods[method];
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: newMethods,
            },
        }));
    };

    const addParameter = (endpoint, method) => {
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: {
                    ...prevDocs.endpoints[endpoint],
                    [method]: {
                        ...prevDocs.endpoints[endpoint][method],
                        parameters: {
                            ...prevDocs.endpoints[endpoint][method].parameters,
                            "": {
                                "description": "",
                                "required": false,
                            },
                        },
                    },
                },
            },
        }));
    };

    const deleteParameter = (endpoint, method, param) => {
        const newParams = { ...docs.endpoints[endpoint][method].parameters };
        delete newParams[param];
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: {
                    ...prevDocs.endpoints[endpoint],
                    [method]: {
                        ...prevDocs.endpoints[endpoint][method],
                        parameters: newParams,
                    },
                },
            },
        }));
    };

    const updateApiDocs = (path, newValue) => {
        if (!Array.isArray(path) || path.length === 0) {
            throw new Error("Path must be a non-empty array");
        }

        let current = docs;
        for (let i = 0; i < path.length - 1; i++) {
            if (!current[path[i]]) {
                throw new Error(`Path not found: ${path.slice(0, i + 1).join('.')}`);
            }
            current = current[path[i]];
        }

        const key = path[path.length - 1];
        if (!(key in current)) {
            throw new Error(`Key not found: ${key}`);
        }

        current[key] = newValue;
        setDocs({ ...docs });
    };

    const updateEndpointTitle = (oldEndpoint, newEndpoint) => {
        if (!docs.endpoints || !docs.endpoints[oldEndpoint]) return;

        setDocs((prevDocs) => {
            const newEndpoints = {};
            Object.keys(prevDocs.endpoints).forEach((key) => {
                if (key === oldEndpoint) {
                    newEndpoints[newEndpoint] = prevDocs.endpoints[oldEndpoint];
                } else {
                    newEndpoints[key] = prevDocs.endpoints[key];
                }
            });
            return { ...prevDocs, endpoints: newEndpoints };
        });

        // Restore focus after the state update
        setTimeout(() => {
            if (endpointsRef.current[newEndpoint]) {
                endpointsRef.current[newEndpoint].focus();
            }
        }, 0);
    };

    const handleMethodNameChange = (endpoint, method, value) => {
        // Do nothing if the new method name already exists and isn't the current one
        if (value !== method && docs.endpoints[endpoint][value] !== undefined) {
            return;
        }
        const newMethods = {};
        Object.keys(docs.endpoints[endpoint]).forEach((key) => {
            if (key === method) {
                newMethods[value] = docs.endpoints[endpoint][method];
            } else {
                newMethods[key] = docs.endpoints[endpoint][key];
            }
        });
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: newMethods,
            },
        }));

        // Restore focus after the state update
        setTimeout(() => {
            if (methodsRef.current[value]) {
                methodsRef.current[value].focus();
            }
        }, 0);
    };

    const handleNestedInputChange = (section, subKey, field, value) => {
        console.log(section, subKey, field, value);
        const endPoints = docs.endpoints;

        endPoints[section][subKey][field] = value;
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: endPoints,
        }));
    };

    const editHeaders = (section, subKey, field, value) => {
        const newDocs = docs

        console.log(newDocs[section][subKey][field]);

        newDocs[section][subKey][field] = value;
        setDocs((prevDocs) => ({
            ...prevDocs,
            newDocs
        }));
    };

    const handleParamterTitleChange = (endpoint, method, param, value) => {
        const newParams = {};
        Object.keys(docs.endpoints[endpoint][method].parameters).forEach((key) => {
            if (key === param) {
                newParams[value] = docs.endpoints[endpoint][method].parameters[param];
            } else {
                newParams[key] = docs.endpoints[endpoint][method].parameters[key];
            }
        });
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: {
                    ...prevDocs.endpoints[endpoint],
                    [method]: {
                        ...prevDocs.endpoints[endpoint][method],
                        parameters: newParams,
                    },
                },
            },
        }));

        // Restore focus after the state update
        setTimeout(() => {
            if (parameterTitleRef.current[value]) {
                parameterTitleRef.current[value].focus();
            }
        }, 0);
    };

    const handleParameterDescriptionChange = (endpoint, method, param, value) => {
        const newParams = {};
        Object.keys(docs.endpoints[endpoint][method].parameters).forEach((key) => {
            if (key === param) {
                newParams[key] = { ...docs.endpoints[endpoint][method].parameters[param], description: value };
            } else {
                newParams[key] = docs.endpoints[endpoint][method].parameters[key];
            }
        });
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: {
                    ...prevDocs.endpoints[endpoint],
                    [method]: {
                        ...prevDocs.endpoints[endpoint][method],
                        parameters: newParams,
                    },
                },
            },
        }));

        // Restore focus after the state update
        setTimeout(() => {
            if (parameterDescriptionRef.current[param]) {
                parameterDescriptionRef.current[param].focus();
            }
        }, 0);
    };

    const handleStatusCodeChange = (endpoint, method, value) => {
        const newResponse = { ...docs.endpoints[endpoint][method].response, status: value };
        setDocs((prevDocs) => ({
            ...prevDocs,
            endpoints: {
                ...prevDocs.endpoints,
                [endpoint]: {
                    ...prevDocs.endpoints[endpoint],
                    [method]: {
                        ...prevDocs.endpoints[endpoint][method],
                        response: newResponse,
                    },
                },
            },
        }));
    };

    const [tempJson, setTempJson] = useState({}); // Store JSON for each endpoint/method

    const handleTempChange = (endpoint, method, value) => {
        setTempJson((prev) => ({
            ...prev,
            [`${endpoint}-${method}`]: value, // Store JSON per endpoint-method combo
        }));
    };

    const handleSaveExample = (endpoint, method) => {
        try {
            const key = `${endpoint}-${method}`;
            const parsedValue = JSON.parse(tempJson[key]); // Validate JSON
            console.log("Saving valid JSON:", parsedValue);

            setDocs((prevDocs) => ({
                ...prevDocs,
                endpoints: {
                    ...prevDocs.endpoints,
                    [endpoint]: {
                        ...prevDocs.endpoints[endpoint],
                        [method]: {
                            ...prevDocs.endpoints[endpoint][method],
                            response: {
                                ...prevDocs.endpoints[endpoint][method].response,
                                example: parsedValue, // Store as an object
                            },
                        },
                    },
                },
            }));
        } catch (error) {
            console.error("Invalid JSON input:", error);
            alert("Invalid JSON! Please fix formatting before clicking away.");
        }
    };

    // Load initial JSON only for the clicked endpoint/method
    const handleFocus = (endpoint, method, details) => {
        const key = `${endpoint}-${method}`;
        if (!tempJson[key]) {
            setTempJson((prev) => ({
                ...prev,
                [key]: JSON.stringify(details.response.example, null, 2),
            }));
        }
    };

    const handleErrorCodeChange = (oldCode, key, newCode) => {
        if (!docs.errors || !docs.errors[oldCode] || newCode === oldCode) return;

        setDocs((prevDocs) => {
            const newErrors = {};

            Object.entries(prevDocs.errors).forEach(([code, details]) => {
                if (code === oldCode) {
                    newErrors[newCode] = details; // Replace old key with new key
                } else {
                    newErrors[code] = details; // Keep the order intact
                }
            });

            return {
                ...prevDocs,
                errors: newErrors,
            };
        });

        // Restore focus after the state update
        setTimeout(() => {
            if (errorCodeRef.current[newCode]) {
                errorCodeRef.current[newCode].focus();
            }
        }, 0);
    };

    const handleErrorDescriptionChange = (code, value) => {
        setDocs((prevDocs) => ({
            ...prevDocs,
            errors: {
                ...prevDocs.errors,
                [code]: {
                    ...prevDocs.errors[code],
                    description: value,
                },
            },
        }));
    };

    const addDeleteError = (action, code) => {
        if (action === 'add') {
            setDocs((prevDocs) => ({
                ...prevDocs,
                errors: {
                    ...prevDocs.errors,
                    [code]: {
                        description: "",
                    },
                },
            }));
        } else {
            const newErrors = { ...docs.errors };
            delete newErrors[code];
            setDocs((prevDocs) => ({
                ...prevDocs,
                errors: newErrors,
            }));
        }
    };

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [endpointToDelete, setEndpointToDelete] = useState(null);

    if (loading) return <p className="ml-32">Loading API documentation...</p>;
    if (error) return <p className="ml-32">Error: {error}</p>;

    return (
        <div className="flex mt-20 text-spotGrey flex-col ml-32 font-rubik pb-12">
            <h1 className="text-4xl ml-6 font-bold">API Documentation Editor</h1>

            {/* Delete Endpoint confirmation modal */}
            {deleteModalOpen && (
                <div className="fixed z-[1000] inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">Are you sure you want to delete this endpoint?</h2>
                        <div className="flex justify-center gap-4">
                            <button className="bg-red-500 text-white p-2 rounded-lg" onClick={() => setDeleteModalOpen(false)}>
                                Cancel
                            </button>
                            <button className="bg-spotYellow text-spotGrey p-2 rounded-lg" onClick={() => deleteEndpoint(endpointToDelete)}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="p-6 w-full">
                {Object.entries(docs).map(([sectionKey, section]) => (
                    <div key={sectionKey} className="mb-8">
                        {/* TOP TITLE */}
                        {section.title && (
                            <h1 className="text-3xl font-bold mb-4">
                                <input
                                    type="text"
                                    className="w-full bg-white border border-gray-700 p-2 rounded outline-spotYellow"
                                    value={section.title || ""}
                                    onChange={(e) => updateApiDocs([sectionKey, "title"], e.target.value)}
                                />
                            </h1>
                        )}

                        {section.content && (
                            <TextareaAutosize
                                className="w-full p-2 bg-white border border-gray-700 rounded focus:outline-spotYellow"
                                value={section.content || ""}
                                onChange={(e) => updateApiDocs([sectionKey, "content"], e.target.value)}
                            />
                        )}

                        {section.headers && (
                            <div className="mt-4">
                                <h3 className="text-xl font-bold">Headers</h3>
                                {Object.entries(section.headers).map(([header, value]) => (
                                    <div key={header} className="mb-2 flex flex-row items-center gap-2 justify-start">
                                        <h3 className="text-xl font-normal mt-4 text-nowrap">{header}:</h3>
                                        <input
                                            type="text"
                                            className="bg-white border border-gray-700 p-2 rounded w-full outline-spotYellow"
                                            value={value}
                                            onChange={(e) => editHeaders(sectionKey, "headers", header, e.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {section.example && (
                            <div className="mb-2 flex flex-row items-center gap-2 justify-start">
                                <h3 className="text-xl font-normal text-nowrap">Example Request:</h3>
                                <TextareaAutosize
                                    className="w-full p-2 bg-white border border-gray-700 rounded outline-spotYellow"
                                    value={section.example.curl || ""}
                                    onChange={(e) => editHeaders(sectionKey, "example", "curl", e.target.value)}
                                />
                            </div>
                        )}

                        {sectionKey === "endpoints" && (
                            <div>
                                {Object.entries(section).map(([endpoint, methods]) => (
                                    <div key={endpoint} className="mb-6">
                                        <h3 className="text-2xl font-bold cursor-pointer flex items-center">
                                            <RiArrowDropRightLine
                                                size={50}
                                                className={`transform transition-transform -ml-6 mt-3 ${toggledEndpoints[endpoint] ? "rotate-90" : "rotate-0"}`}
                                                onClick={() => toggleEndpoint(endpoint)}
                                            />
                                            <input
                                                type="text"
                                                placeholder="Endpoint Name"
                                                className="bg-white border border-gray-700 p-2 rounded outline-spotYellow"
                                                value={endpoint}
                                                ref={(el) => (endpointsRef.current[endpoint] = el)}
                                                onChange={(e) => updateEndpointTitle(endpoint, e.target.value)}
                                            />
                                            <div
                                                onClick={() => {
                                                    setEndpointToDelete(endpoint);
                                                    setDeleteModalOpen(true);
                                                }}
                                                className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg group mt-4 ml-4"
                                            >
                                                <FaTrashAlt className="text-2xl cursor-pointer text-spotGrey group-hover:text-red-500" />
                                            </div>
                                        </h3>
                                        {toggledEndpoints[endpoint] && (
                                            <div className="pl-6 border-l-2 mt-4 border-spotYellow">
                                                {Object.entries(methods).map(([method, details]) => (
                                                    <div key={method} className="border-l-2 border-l-spotGrey mb-4 pl-4">
                                                        <hr className="mb-4 h-2" />
                                                        <div className="flex flex-row items-center gap-2 mb-2">
                                                            <input
                                                                type="text"
                                                                placeholder="Method Name"
                                                                className="bg-white border outline-spotYellow text-xl font-bold border-gray-700 mt-0 p-2 rounded w-full"
                                                                value={method.toUpperCase()}
                                                                ref={(el) => { methodsRef.current[method] = el }}
                                                                onChange={(e) => handleMethodNameChange(endpoint, method, e.target.value)}
                                                            />
                                                            <div
                                                                onClick={() => deleteMethod(endpoint, method)}
                                                                className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg group"
                                                            >
                                                                <FaTrashAlt className="text-2xl cursor-pointer text-spotGrey group-hover:text-red-500" />
                                                            </div>
                                                        </div>
                                                        <TextareaAutosize
                                                            className="w-full p-2 bg-white border border-gray-700 rounded focus:outline-spotYellow"
                                                            value={details.description || ""}
                                                            placeholder="Method Description"
                                                            onChange={(e) => handleNestedInputChange(endpoint, method, "description", e.target.value)}
                                                        />

                                                        {/* PARAMETERS */}
                                                        {details.parameters && (
                                                            <div className="mt-2">
                                                                <h5 className="font-bold text-xl mb-2">Parameters: </h5>
                                                                {Object.entries(details.parameters).map(([param, paramDetails]) => (
                                                                    <div key={param} className="mb-2 w-full flex flex-row items-center gap-2">
                                                                        <div className="w-full flex flex-col justify-start">
                                                                            <p className="w-full text-left">Title</p>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Parameter Name"
                                                                                ref={(el) => (parameterTitleRef.current[param] = el)}
                                                                                className="bg-white border outline-spotYellow border-gray-700 mt-0 p-2 rounded w-full"
                                                                                value={param}
                                                                                onChange={(e) => handleParamterTitleChange(endpoint, method, param, e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <p className="mt-5">-</p>
                                                                        <div className="w-full flex flex-col justify-center items-center">
                                                                            <p className="w-full text-left">Description</p>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Parameter Description"
                                                                                ref={(el) => (parameterDescriptionRef.current[param] = el)}
                                                                                className="bg-white border outline-spotYellow border-gray-700 mt-0 p-2 rounded w-full"
                                                                                value={paramDetails.description || ""}
                                                                                onChange={(e) => handleParameterDescriptionChange(endpoint, method, param, e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="flex flex-col justify-center items-center gap-1 mt-3">
                                                                            <p>Required</p>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mt-0"
                                                                                checked={paramDetails.required}
                                                                                onChange={(e) => handleNestedInputChange(endpoint, method, "parameters", param, e.target.checked)}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            onClick={() => deleteParameter(endpoint, method, param)}
                                                                            className="px-4 py-2 mt-4 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg group"
                                                                        >
                                                                            <FaTrashAlt className="text-2xl cursor-pointer text-spotGrey group-hover:text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="flex flex-row gap-2">
                                                                    <div
                                                                        onClick={() => addParameter(endpoint, method)}
                                                                        className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg"
                                                                    >
                                                                        <p className="text-sm">New Parameter</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* RESPONSE */}
                                                        {details.response && (
                                                            <div className="mt-4">
                                                                <h5 className="font-bold text-xl">Response: </h5>
                                                                <div className="flex flex-row items-center gap-2 mb-2">
                                                                    <p className="font-semibold mt-3">Status Code:</p>
                                                                    <input
                                                                        type="number"
                                                                        className="bg-white border outline-spotYellow border-gray-700 p-2 rounded"
                                                                        value={details.response.status || ""}
                                                                        onChange={(e) => handleStatusCodeChange(endpoint, method, e.target.value)}
                                                                    />
                                                                </div>
                                                                <p className="font-semibold mb-2">Response Example:</p>
                                                                <TextareaAutosize
                                                                    className="w-full p-2 bg-white font-mono border border-gray-700 rounded focus:outline-spotYellow"
                                                                    value={tempJson[`${endpoint}-${method}`] || ""} // Get specific JSON
                                                                    placeholder="Click to load response"
                                                                    onChange={(e) => handleTempChange(endpoint, method, e.target.value)} // Update specific key
                                                                    onFocus={() => handleFocus(endpoint, method, details)} // Load specific JSON
                                                                    onBlur={() => handleSaveExample(endpoint, method)} // Save only for this response
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                                <div className="flex flex-row gap-2">
                                                    <div onClick={() => addNewMethod(endpoint, "")} className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg">
                                                        <p className="text-sm">New Method</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="flex flex-row gap-2">
                                    <div onClick={() => addNewEndpoint("")} className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg">
                                        <p className="text-sm">New Endpoint</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {sectionKey === "errors" && (
                            <div className="mb-6 p-4">
                                <h2 className="text-2xl font-bold mb-3">Error Codes</h2>
                                <ul className="space-y-2">
                                    {Object.entries(docs.errors).map(([code, details]) => (
                                        <li key={code} className="flex items-start">
                                            <input
                                                type="text"
                                                className="font-semibold text-red-500 mr-2 bg-transparent border-b border-red-500 focus:outline-none"
                                                value={code}
                                                ref={(el) => (errorCodeRef.current[code] = el)}
                                                onChange={(e) => handleErrorCodeChange(code, "code", e.target.value)}
                                            />
                                            <input
                                                type="text"
                                                className="text-spotGrey bg-transparent border-b border-gray-400 focus:outline-none w-full"
                                                value={details.description || ""}
                                                onChange={(e) => handleErrorDescriptionChange(code, e.target.value)}
                                            />
                                            <div
                                                onClick={() => addDeleteError('delete', code)}
                                                className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg group ml-4"
                                            >
                                                <FaTrashAlt className="text-2xl cursor-pointer text-spotGrey group-hover:text-red-500" />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="flex flex-row gap-2 mt-2">
                                    <div
                                        onClick={() => addDeleteError('add', "")}
                                        className="px-4 py-2 border cursor-pointer bg-white rounded-xl flex justify-center items-center hover:shadow-lg"
                                    >
                                        <p className="text-sm">New Error Code</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* SAVE BUTTON */}
            <button onClick={saveToS3} className="mb-4 p-2 bg-spotYellow rounded-lg px-3 fixed right-10 bottom-10 font-bold text-spotGrey hover:scale-105 transition shadow-lg">
                Save Changes
            </button>
        </div>
    );
}