import React from "react";
import HealthUtils from "./health";

const HealthIndicator = ({ healthPercent }) => {
    return (
        <div className="flex items-center space-x-4">
            <div className="relative w-16 h-16">
                <svg
                    className="transform -rotate-90"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="18"
                        cy="18"
                        r="16"
                        stroke="#e5e7eb"
                        strokeWidth="4"
                        fill="none"
                    />
                    <circle
                        cx="18"
                        cy="18"
                        r="16"
                        stroke={HealthUtils.getHealthColor(healthPercent)}
                        strokeWidth="4"
                        fill="none"
                        strokeDasharray="100"
                        strokeDashoffset={100 - healthPercent}
                        strokeLinecap="round"
                    />
                </svg>
                <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-lg font-semibold text-gray-800">{healthPercent}%</span>
                </div>
            </div>
            <h2 className="hidden sm:block text-2xl font-bold select-none">Health</h2>
        </div>
    );
};

export default HealthIndicator;
