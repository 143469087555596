import { fetchAllData } from "../../../fetcher";

/**
 * Generates the data for the total users graph.
 * 
 * @param {Array} data - Array of user objects containing account creation dates.
 * @returns {Object} - An object containing the count of users and chart data for Recharts.
 */
const getTotalUsersGraph = async (data) => {
    const count = data ? data.length : 0;

    // Map and validate dates
    const signUpDates = data
        ? data.map(user => {
            const date = new Date(user.accountCreationDate);
            if (isNaN(date)) {
                console.warn('Invalid Date:', user.accountCreationDate);
                return null;
            }
            const startOfWeek = new Date(date);
            startOfWeek.setDate(date.getDate() - date.getDay()); // Start of the week
            return startOfWeek;
        }).filter(date => date !== null)
        : [];

    // Aggregate signup counts by week
    const signUpCounts = signUpDates.reduce((acc, date) => {
        const dateString = date.toISOString().split('T')[0];
        acc[dateString] = (acc[dateString] || 0) + 1;
        return acc;
    }, {});

    // Determine the range of dates
    const allDates = [];
    const currentDate = new Date(Math.min(...signUpDates.map(date => date.getTime())));
    const lastDate = new Date(Math.max(...signUpDates.map(date => date.getTime())));

    while (currentDate <= lastDate) {
        const dateString = currentDate.toISOString().split('T')[0];
        allDates.push(dateString);
        currentDate.setDate(currentDate.getDate() + 7); // Weekly intervals
    }

    // Fill missing weeks with zero counts
    const filledSignUpCounts = allDates.map(date => ({
        date,
        count: signUpCounts[date] || 0,
    }));

    return { count, lineData: filledSignUpCounts };
};

// IMPORTANT: Creating a card
// 1. Add the setter you created in metricCards/context/MetricsContext.js to the getData function
// 2. Use process data to get the data you want from either the main response, godPortalMetrics, or godPortalSettings
// 3. Add the data to the return object in processData

/**
 * Processes the fetched data and returns a subset of metrics.
 * @param {Object} response - The fetched data from different spot API's.
 * @param {Array} godPortalMetrics - An array of metrics fetched specifically from the god portal.
 * @param {Object} godPortalSettings - Settings fetched from the god portal.
 * @returns {Object} - An object containing a subset of metrics.
 */
const processData = async (response, godPortalMetrics, godPortalSettings) => {
    const metricsObject = godPortalMetrics.reduce((acc, metric) => {
        acc[metric.metricName] = metric;
        return acc;
    }, {});

    const allOrgs = await response.allOrgs || [];
    const realActiveOrgs = new Set(allOrgs.filter(org => org.active && org.orgID !== "000001").map(org => org.orgID));

    const totalOrgs = allOrgs.filter(org => org.orgID !== "000001").length;
    const numLots = allOrgs.reduce((sum, org) => (org.orgID !== "000001" ? sum + org.lots.length : sum), 0);
    const numActiveLots = allOrgs.reduce(
        (sum, org) => (realActiveOrgs.has(org.orgID) ? sum + org.lots.length : sum),
        0
    );

    const totalSpots = await response.spotCount || [];
    const activeSpots = totalSpots.filter(spot => spot.on === "true");
    const vehiclesTracked = activeSpots.filter(spot => spot.licensePlate !== "");

    const allUsers = await response.allUsers || [];
    let navUsers = 0, enforcementUsers = 0, adminUsers = 0;

    allUsers.forEach(user => {
        if (!user.apps) return;
        Object.values(user.apps).forEach(app => {
            if (app.nav) navUsers++;
            if (app.enforcement) enforcementUsers++;
            if (app.admin) adminUsers++;
        });
    });

    const totalUsersGraphData = await getTotalUsersGraph(allUsers);

    const pinData = [];
    allUsers.forEach(user => {
        if (user.lotRequests) {
            user.lotRequests.forEach(request => {
                pinData.push({
                    pinCoordinates: request.coordinates,
                    userName: `${user.settings.personalInfo.firstName} ${user.settings.personalInfo.lastName}`,
                });
            });
        }
    });

    const qrScans = (await response.qrScans)?.value || 0;

    const overheadCameras = [];
    const lprCameras = [];
    allOrgs.forEach(org => {
        org.lots.forEach(lot => {
            lot.cameras?.forEach(camera => {
                if (camera.type === "overhead") overheadCameras.push(camera);
                if (camera.type === "lpr") lprCameras.push(camera);
            });
        });
    });

    const imagesSaved = metricsObject.images_saved.metricValue;
    const ovhSize = metricsObject.ovhdo_image_size.metricValue;
    const ovhProcessing = metricsObject.ovhdo_image_processing_time.metricValue;
    const ovhIteration = metricsObject.ovhdo_iteration_processing_time.metricValue;
    const ovhSendOccupancy = metricsObject.ovhdo_send_occupancy_time.metricValue;
    const cameraStatus = metricsObject.camera_status.metricValue;
    const sentryStatus = metricsObject.sentry_status.metricValue;
    const platesRead = {
        validated: metricsObject.plates_read.metricValue.validated,
        nonValidated: metricsObject.plates_read.metricValue["non-validated"],
    };
    const violations = {
        total: metricsObject.violations.metricValue.total,
        citations: metricsObject.violations.metricValue.citations,
    }
    const avgLprTime = metricsObject.lpr_time.metricValue;
    const avgVideoSize = metricsObject.video_file_size.metricValue;
    const avgVideoProcessingTime = metricsObject.video_processing_time.metricValue;
    const appDownloads = metricsObject.app_downloads.metricValue;
    const activeAppUsers = {
        day: metricsObject.active_app_users.metricValue.day,
        week: metricsObject.active_app_users.metricValue.week,
        month: metricsObject.active_app_users.metricValue.month,
        year: metricsObject.active_app_users.metricValue.year,
    }
    const appCrashes = metricsObject.app_crashes.metricValue;
    const revenue = metricsObject.revenue.metricValue;
    const expenses = metricsObject.expenses.metricValue;

    const thresholds = {};
    Object.entries(godPortalSettings).forEach(([key, setting]) => {
        thresholds[setting.settingName] = {
            error: setting.settingData.error,
            warning: setting.settingData.warning,
        };
    });

    return {
        orgs: { active: realActiveOrgs.size, total: totalOrgs },
        spotCount: activeSpots.length,
        vehiclesTracked: vehiclesTracked.length,
        cameraCount: { overhead: overheadCameras.length, lpr: lprCameras.length },
        userCount: { total: allUsers.length, nav: navUsers, enforcement: enforcementUsers, admin: adminUsers },
        totalUsersGraphData,
        lotRequestCount: pinData.length,
        downloadQRScans: qrScans,
        lots: { active: numActiveLots, total: numLots },
        imagesSaved,
        ovhSize: ovhSize,
        ovhProcessing: ovhProcessing,
        ovhIteration: ovhIteration,
        ovhSendOccupancy: ovhSendOccupancy,
        cameraStatus: cameraStatus,
        sentryStatus: sentryStatus,
        platesRead: platesRead,
        thresholds: thresholds,
        violations: violations,
        avgLprTime: avgLprTime,
        avgVideoSize: avgVideoSize,
        avgVideoProcessingTime: avgVideoProcessingTime,
        appDownloads: appDownloads,
        activeAppUsers: activeAppUsers,
        appCrashes: appCrashes,
        revenue: revenue,
        expenses: expenses,
    };
};


/**
 * Fetches various data metrics.
 * @returns {Object} - An object containing all the fetched data metrics.
 */
const fetchData = async () => {
    const urls = {
        spotCount: "/000004/spots",
        allOrgs: "/general/organizations",
        allUsers: "/general/spotUsers",
        qrScans: "/general/spotMetric?metricName=qr_counter",
    };
    const response = await fetchAllData(urls, "prod");

    try {
        const godPortalMetricsResponse = await fetch("https://api.spotparking.app/SpotAPI/general/godPortalMetrics", {
            headers: { "x-api-key": process.env.REACT_APP_METRIC_API_KEY },
        });

        const godPortalMetrics = await godPortalMetricsResponse.json();

        const godPortalSettingsResponse = await fetch("https://api.spotparking.app/SpotAPI/general/godPortalSettings", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-api-key": process.env.REACT_APP_METRIC_API_KEY
            }
        })

        const godPortalSettings = await godPortalSettingsResponse.json();

        return await processData(response, godPortalMetrics, godPortalSettings);
    }
    catch (error) {
        console.error("Error fetching god portal metrics:", error);
        alert("Error fetching god portal metrics");
        return null;
    }
};

/**
 * Updates state variables with fetched data.
 * @param {Object} setters - An object containing setter functions for various state variables.
 */
export const getData = async (setters) => {
    const data = await fetchData();
    setters.setOrgs(data.orgs);
    setters.setSpotCount(data.spotCount);
    setters.setVehiclesTracked(data.vehiclesTracked);
    setters.setCameraCount(data.cameraCount);
    setters.setUserCount(data.userCount);
    setters.setTotalUsersGraphData(data.totalUsersGraphData);
    setters.setLotRequestCount(data.lotRequestCount);
    setters.setDownloadQRScans(data.downloadQRScans);
    setters.setLots(data.lots);
    setters.setImagesSaved(data.imagesSaved);
    setters.setOvhSize(data.ovhSize);
    setters.setOvhProcessing(data.ovhProcessing);
    setters.setOvhIteration(data.ovhIteration);
    setters.setOvhSendOccupancy(data.ovhSendOccupancy);
    setters.setCameraStatus(data.cameraStatus);
    setters.setSentryStatus(data.sentryStatus);
    setters.setPlatesRead(data.platesRead);
    setters.setThresholds(data.thresholds);
    setters.setViolations(data.violations);
    setters.setAvgLprTime(data.avgLprTime);
    setters.setAvgVideoSize(data.avgVideoSize);
    setters.setAvgVideoProcessingTime(data.avgVideoProcessingTime);
    setters.setAppDownloads(data.appDownloads);
    setters.setActiveAppUsers(data.activeAppUsers);
    setters.setAppCrashes(data.appCrashes);
    setters.setRevenue(data.revenue);
    setters.setExpenses(data.expenses);
};
