import React, { useState, useEffect } from "react";
import { HiXMark } from "react-icons/hi2";
import { fetchData, postData } from "../../../fetcher";

export const VideosEditor = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingCategory, setEditingCategory] = useState(null);
    const [categoryInput, setCategoryInput] = useState({});

    useEffect(() => {
        async function getData() {
            try {
                const response = await fetchData("/public/videoHelp");
                console.log(response)
                setData(response);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        getData();
    }, []);

    const updateField = (category, index, field, value) => {
        setData((prevData) => {
            const newData = { ...prevData };
            newData.videos[category][index][field] = value;
            return newData;
        });
    };

    const handleCategoryChange = (oldCategory, newCategory) => {
        setCategoryInput((prev) => ({ ...prev, [oldCategory]: newCategory }));
    };

    const handleCategoryBlur = (oldCategory) => {
        const newCategory = categoryInput[oldCategory]?.trim();
        if (newCategory && newCategory !== oldCategory) {
            updateCategoryName(oldCategory, newCategory);
        }
        setEditingCategory(null);
    };

    const updateCategoryName = (oldCategory, newCategory) => {

        if (newCategory.trim() === "" || oldCategory === newCategory) return;

        setData((prevData) => {
            if (!prevData.videos[oldCategory]) return prevData; // Ensure old category exists

            const updatedVideos = {};

            // Maintain order while renaming the category
            Object.entries(prevData.videos).forEach(([key, value]) => {
                if (key === oldCategory) {
                    updatedVideos[newCategory] = value; // Rename category
                } else {
                    updatedVideos[key] = value; // Keep other categories unchanged
                }
            });

            return {
                ...prevData,
                videos: updatedVideos
            };
        });
    };

    const addCategory = () => {
        const newCategory = "";
        setData((prevData) => ({
            ...prevData,
            videos: { ...prevData.videos, [newCategory]: [] },
        }));
    };

    const addVideo = (category) => {
        setData((prevData) => {
            const newData = { ...prevData };
            newData.videos[category].push({
                title: "",
                description: "",
                url: "",
                thumbnail: "",
            });
            return newData;
        });
    };

    const deleteVideo = (category, index) => {
        setData((prevData) => {
            const newData = { ...prevData };
            newData.videos[category].splice(index, 1);
            return newData;
        });
    };

    const deleteCategory = (category) => {
        setData((prevData) => {
            const newData = { ...prevData };
            delete newData.videos[category];
            return newData;
        });
    };

    const saveJsonToS3 = async () => {
        console.log("Updated JSON:", JSON.stringify(data, null, 2));

        const response = await postData("/public/videoHelp", data);
        console.log(response);
    };

    if (loading) return <div className="text-white text-center mt-10">Loading...</div>;
    if (error) return <div className="text-red-500 text-center mt-10">Error: {error}</div>;

    return (
        <div className="w-full pl-28 mt-10 p-8 text-spotGray">
            <h1 className="text-4xl font-bold text-center mb-6">Videos Editor</h1>

            {Object.keys(data.videos).map((category) => (
                <div key={category} className="mb-8 border-b border-gray-700 pb-4">
                    <div className="flex justify-between items-center mb-4">
                        <input
                            type="text"
                            value={editingCategory === category ? categoryInput[category] || category : category}
                            placeholder="Category Name"
                            onFocus={() => {
                                setEditingCategory(category);
                                setCategoryInput((prev) => ({ ...prev, [category]: category }));
                            }}
                            onChange={(e) => handleCategoryChange(category, e.target.value)}
                            onBlur={() => handleCategoryBlur(category)}
                            className="px-2 py-1 rounded-lg w-2/3 border outline-spotYellow"
                        />
                        <button
                            onClick={() => deleteCategory(category)}
                            className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-lg mt-4"
                        >
                            Delete Category
                        </button>
                    </div>

                    <div className="grid grid-cols-3 gap-4">
                        {data.videos[category].map((video, index) => (
                            <div key={index} className="p-4 rounded-lg mb-4 w-auto border shadow-md roudned-lg relative group">
                                <input
                                    type="text"
                                    value={video.title}
                                    onChange={(e) => updateField(category, index, "title", e.target.value)}
                                    placeholder="Video Title"
                                    className="w-full mb-2 px-2 py-1 rounded-lg border outline-spotYellow"
                                />
                                <textarea
                                    value={video.description}
                                    onChange={(e) => updateField(category, index, "description", e.target.value)}
                                    placeholder="Video Description"
                                    className="w-full mb-2 px-2 py-1 rounded-lg border outline-spotYellow"
                                />
                                <input
                                    type="text"
                                    value={video.url}
                                    onChange={(e) => updateField(category, index, "url", e.target.value)}
                                    className="w-full mb-2 px-2 py-1 rounded-lg border outline-spotYellow"
                                    placeholder="Video URL"
                                />
                                <input
                                    type="text"
                                    value={video.thumbnail}
                                    onChange={(e) => updateField(category, index, "thumbnail", e.target.value)}
                                    className="w-full mb-2 px-2 py-1 rounded-lg border outline-spotYellow"
                                    placeholder="Thumbnail URL"
                                />
                                <button
                                    onClick={() => deleteVideo(category, index)}
                                    className="bg-red-500 hover:bg-red-600 invisible group-hover:visible transition text-white p-1 rounded-full absolute -top-2 -right-2"
                                >
                                    <HiXMark size={20} />
                                </button>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={() => addVideo(category)}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded-lg"
                    >
                        Add Video
                    </button>
                </div>
            ))}

            <button
                onClick={addCategory}
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg mb-4"
            >
                Add Category
            </button>

            <button
                onClick={saveJsonToS3}
                className="bg-spotYellow font-bold hover:scale-[102%] transition text-white px-4 py-2 rounded-lg w-full"
            >
                Save JSON to S3
            </button>
        </div>
    );
};