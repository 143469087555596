/**
 * Calculate the health percentage of the cards.
 * @param {Array} cards - The array of card objects.
 * @returns {number} - The health percentage.
 */
const calcHealthPercent = (cards) => {
    let warningCount = 0;
    let errorCount = 0;

    cards.forEach(({ props: { data1, data, warning, error, reverse } }) => {
        if (!reverse) {
            if (data1 > error) errorCount++;
            else if (data1 > warning) warningCount++;
            if (data) {
                if (Object.keys(data).length > 0) {
                    if (data && Object.values(data).some((value) => value > error)) errorCount++;
                    else if (data && Object.values(data).some((value) => value > warning)) warningCount++;
                }
            }
        } else {
            if (data1 < error) errorCount++;
            else if (data1 < warning) warningCount++;
            if (data) {
                if (Object.keys(data).length > 0) {
                    if (data && Object.values(data).some((value) => value < error)) errorCount++;
                    else if (data && Object.values(data).some((value) => value < warning)) warningCount++;
                }
            }
        }
    });

    const total = cards.length;
    const percentage = total > 0 ? Math.max(0, 100 - (errorCount * 10 + warningCount * 2)) : 100; // Adjust weight for error and warning
    return Math.round(percentage);
};

/**
 * Get the color representing the health percentage.
 * @param {number} percent - The health percentage.
 * @returns {string} - The color representing the health percentage.
 */
const getHealthColor = (percent) => {
    if (percent > 75) return "green";
    if (percent > 50) return "yellow";
    return "red";
};

/**
 * Get the status of a card based on its data.
 * @param {Number} data1 - The primary data value for simple cards and dual cards
 * @param {Object} data - The data object for filterable cards
 * @param {Number} error - The error threshold
 * @param {Number} warning - The warning threshold
 * @param {Boolean} reverse - Whether to reverse the comparison logic
 * @returns {string} - The status of the card ("normal", "warning", "error")
 */
const getStatus = ({ data1, data, error, warning, reverse }) => {
    if (!reverse) {
        if (data1 > error) return "error";
        if (data1 > warning) return "warning";
        if (data && Object.keys(data).length > 0) {
            if (Object.values(data).some((value) => value > error)) return "error";
            if (Object.values(data).some((value) => value > warning)) return "warning";
        }
        return "normal";
    } else {
        if (data1 != null ) {
            if (data1 < error) return "error";
            if (data1 < warning) return "warning";
            if (data && Object.keys(data).length > 0) {
                if (Object.values(data).some((value) => value < error)) return "error";
                if (Object.values(data).some((value) => value < warning)) return "warning";
            }
        }
        return "normal";
    }
};

const HealthUtils = {
    calcHealthPercent,
    getHealthColor,
    getStatus,
}

export default HealthUtils;
