import React from "react";
import NumberTicker from "../../utility/NumberTicker";
import BaseMetricCard from "./BaseMetricCard";
import ThresholdEdit from "../config/thresholdEdit";
import { Skeleton } from "../../utility/Skeleton";
import { useCard } from "../context/cardContext";

const DualMetricCard = () => {
    const { dataMetrics, loading } = useCard();

    return (
        <div className="relative w-full h-full">
        <BaseMetricCard mainTitle={dataMetrics.mainTitle}>
            <div className="flex flex-row flex-wrap">
                <div className="flex flex-row items-center gap-2">
                    <h1 className={`text-2xl font-bold ${dataMetrics.data1 !== null && dataMetrics.data1 !== undefined ? 'text-black' : 'text-gray-400'}`}>
                        {!loading && dataMetrics.data1 !== null && dataMetrics.data1 !== undefined ? <NumberTicker
                            from={0}
                            target={dataMetrics.data1}
                            autoStart={true}
                            transition={{ duration: 2.5, type: "tween", ease: "easeInOut" }}
                        /> : <>
                        <Skeleton className="w-36 h-10 mb-2" />
                        <div className="flex flex-row">
                        <Skeleton className="w-20 h-8 mr-2" />
                        <Skeleton className="w-20 h-8" />
                        </div>
                        </>}
                    </h1>
                    <h1 className="text-sm text-gray-500">
                        {!loading && dataMetrics.data1 !== null && dataMetrics.data1 !== undefined ? dataMetrics.subTitle1 : ""}
                    </h1>
                </div>
                {(!loading && dataMetrics.data2 !== null && dataMetrics.data2 !== undefined) && (
                    <div className="ml-4 flex flex-row items-center gap-2">
                        <h1 className={`text-2xl font-bold ${dataMetrics.data2 !== null && dataMetrics.data2 !== undefined ? 'text-black' : 'text-gray-400'}`}>
                            <NumberTicker
                                from={0}
                                target={dataMetrics.data2}
                                autoStart={true}
                                transition={{ duration: 2.5, type: "tween", ease: "easeInOut" }}
                            />
                        </h1>
                        <h1 className="text-sm text-gray-500">
                            {dataMetrics.subTitle2}
                        </h1>
                    </div>
                )}
            </div>
        </BaseMetricCard>
        {(dataMetrics.data1 !== null && dataMetrics.data1 !== undefined && dataMetrics.warning && dataMetrics.error) && (
            <ThresholdEdit />
        )}
        </div>
    );
};

export default DualMetricCard;