const GROUPS = {
    MAIN: "Main",
    CAMERA: "Camera",
    PROCESSING: "Processing",
    USERS: "Users",
    APP: "App",
    CASH: "Cash Money",
};

const COMPONENTS = {
    SIMPLE: "SimpleMetricCard",
    DUAL: "DualMetricCard",
    FILTERABLE: "FilterableMetricCard",
    GRAPH: "GraphMetric",
    LOGS: "LogsCard",
};

/**
 * Generates the configuration data for various metric cards.
 * @param {Object} props The props to use for the metric cards.
 * @returns {Array<Object>} The configuration data for the metric cards.
 */
export const cardData = function(props) {

    // IMPORTANT: Creating a card
    // 1. Use the below configuration options to create a new card.
    // Make sure to inlcude a group, main title, component, and needed props (See types of props below).
    // 2. Use the variable you created in metricCards/context/MeticsContext.js to set the data for the card.
    
    // Card Config Options
    // Each card is an object with a group, component, and props
    // - group: Used to group cards together
    // - component: The type of card to render
    // - props: The props to pass to the card component

    // Types of Props:
    // - mainTitle: The main title of the card (required) STRING
    // - subTitle1: The subtitle of the card (for both simple and dual cards) STRING
    // - subTitle2: The second subtitle of the card (for dual cards) STRING
    // - data1: The data to display in the card (for both simple and dual cards) NUMBER
    // - data2: The second data to display in the card (for dual cards) NUMBER
    // - filters: The filters to display in the card (for filterable cards) ARRAY<STRING>
    // - data: The data to display in the card (specifically for filterable cards) OBJECT
    // - radial: Whether to display the data in a radial format (for filterable cards) BOOLEAN
    // - link: The link to navigate to when the card is clicked (for all cards) STRING
    // - prefix: The prefix to display before the data (for filterable cards) STRING
    // - settingName: The name of the setting to use for setting thresholds in the database (must have for threshold edit button) STRING
    // - warning: The warning threshold for the setting (must have for threshold edit button) NUMBER
    // - error: The error threshold for the setting (must have for threshold edit button) NUMBER
    // - reverse: Whether to reverse the threshold logic BOOLEAN
    // - count: The count of the data to display in the card (for graph cards) NUMBER
    // - lineData: The data to display in the graph (for graph cards) ARRAY<OBJECT>

    // Anything with a lable "Test" in the title has finished logic but had no real data to display from the backend
    
    return  [
    {
        group: GROUPS.MAIN,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Organizations",
            filters: ["Active", "Inactive", "Total"],
            data: {
                Active: props.orgs.active,
                Inactive: props.orgs.total - props.orgs.active,
                Total: props.orgs.total,
            },
            radial: true,
            link: props.links.orgsTable,
        },
    },
    {
        group: GROUPS.MAIN,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Lots Monitored",
            filters: ["Active", "Inactive", "Total"],
            data: {
                Active: props.lots.active,
                Inactive: props.lots.total - props.lots.active,
                Total: props.lots.total,
            },
            radial: true,
            link: props.links.orgsTable,
        },
    },
    {
        group: GROUPS.MAIN,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Spots Monitored",
            data1: props.spotCount,
            link: props.links.spotsTable,
        },
    },
    {
        group: GROUPS.MAIN,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Vehicles Tracked",
            data1: props.vehiclesTracked,
            link: props.links.spotsTable,
            settingName: "vehicles_tracked",
            warning: props.thresholds.vehicles_tracked?.warning ?? Infinity,
            error: props.thresholds.vehicles_tracked?.error ?? Infinity,
            reverse: true,
        },
    },
    {
        group: GROUPS.CAMERA,
        component: COMPONENTS.DUAL,
        props: {
            mainTitle: "Active Cameras",
            subTitle1: "Overhead",
            data1: props.cameraCount.overhead,
            subTitle2: "LPR",
            data2: props.cameraCount.lpr,
            link: props.links.orgsTable,
        },
    },
    {
        group: GROUPS.USERS,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Total Lot Requests",
            data1: props.lotRequestCount,
            link: props.links.usersTable,
        },
    },
    {
        group: GROUPS.USERS,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Download QR Code Scans",
            data1: props.downloadQRScans,
            link: props.links.spotMetricsTable,
        },
    },
    {
        group: GROUPS.USERS,
        component: COMPONENTS.GRAPH,
        props: {
            mainTitle: "Total Users",
            count: props.totalUsersGraphData.count,
            lineData: props.totalUsersGraphData.lineData,
            link: props.links.usersTable,
        },
    },
    {
        group: GROUPS.USERS,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Users by Type",
            filters: ["Nav", "Enforcement", "Admin", "Total"],
            data: {
                Nav: props.userCount.nav,
                Enforcement: props.userCount.enforcement,
                Admin: props.userCount.admin,
                Total: props.userCount.nav + props.userCount.enforcement + props.userCount.admin,
            },
            radial: true,
            link: null,
        },
    },
    {
        group: GROUPS.CAMERA,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Camera Statuses Test",
            data1: props.cameraStatus,
            subTitle1: "Active",
            link: null,
        },
    },
    {
        group: GROUPS.CAMERA,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Sentry Statuses Test",
            data1: props.sentryStatus,
            subTitle1: "Active",
            link: null,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "Images Stored",
            data1: props.imagesSaved,
            link: null,
            settingName: "images_saved",
            warning: props.thresholds.images_saved?.warning ?? Infinity,
            error: props.thresholds.images_saved?.error ?? Infinity,
        },
    },
    {
        group: GROUPS.CAMERA,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Plates Read Test",
            filters: ["Validated", "Non-Validated", "Total"],
            data: {
                Validated: props.platesRead.validated,
                "Non-Validated": props.platesRead.nonValidated,
                Total: props.platesRead.validated + props.platesRead.nonValidated,
            },
            radial: true,
            link: null,
        },
    },
    {
        group: GROUPS.CAMERA,
        component: COMPONENTS.DUAL,
        props: {
            mainTitle: "Violations Test",
            subTitle1: "Total",
            data1: props.violations.total,
            subTitle2: "Written Citations",
            data2: props.violations.citations,
            link: null,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG LPR Time Test",
            data1: props.avgLprTime,
            subTitle1: "ms",
            link: null,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG Video File Size Test",
            data1: props.avgVideoSize,
            subTitle1: "mb",
            link: null,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG Video Processing Time Test",
            data1: props.avgVideoProcessingTime,
            subTitle1: "Seconds",
            link: null,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG OVH Size",
            data1: props.ovhSize,
            subTitle1: "kb",
            link: null,
            settingName: "ovhdo_image_size",
            warning: props.thresholds.ovhdo_image_size?.warning ?? Infinity,
            error: props.thresholds.ovhdo_image_size?.error ?? Infinity,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG OVH Processing Time",
            data1: props.ovhProcessing,
            subTitle1: "Seconds",
            link: null,
            settingName: "ovhdo_image_processing_time",
            warning: props.thresholds.ovhdo_image_processing_time?.warning ?? Infinity,
            error: props.thresholds.ovhdo_image_processing_time?.error ?? Infinity,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG OVH Iteration Time",
            data1: props.ovhIteration,
            subTitle1: "Seconds",
            link: null,
            settingName: "ovhdo_iteration_processing_time",
            warning: props.thresholds.ovhdo_iteration_processing_time?.warning ?? Infinity,
            error: props.thresholds.ovhdo_iteration_processing_time?.error ?? Infinity,
        },
    },
    {
        group: GROUPS.PROCESSING,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "AVG OVH Send Occupancy Time",
            data1: props.ovhSendOccupancy,
            subTitle1: "Seconds",
            link: null,
            settingName: "ovhdo_send_occupancy_time",
            warning: props.thresholds.ovhdo_send_occupancy_time?.warning ?? Infinity,
            error: props.thresholds.ovhdo_send_occupancy_time?.error ?? Infinity,
        },
    },
    {
        group: GROUPS.APP,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "App Downloads Test",
            data1: props.appDownloads,
            subTitle1: "Total",
            link: null,
        },
    },
    {   
        group: GROUPS.APP,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Active App Users Test",
            filters: ["Day", "Week", "Month", "Year"],
            data: {
                Day: props.activeAppUsers?.day,
                Week: props.activeAppUsers?.week,
                Month: props.activeAppUsers?.month,
                Year:  props.activeAppUsers?.year,
            },
            link: null,
        },
    },
    {
        group: GROUPS.APP,
        component: COMPONENTS.SIMPLE,
        props: {
            mainTitle: "App Crashes Test",
            data1: props.appCrashes,
            subTitle1: "This Month",
            link: null,
        },
    },
    {
        group: GROUPS.CASH,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Revenue Test",
            prefix: "$",
            filters: ["Month", "Year"],
            data: {
                Month: props.revenue?.month,
                Year: props.revenue?.year,
            },
            link: null,
        },
    },
    {
        group: GROUPS.CASH,
        component: COMPONENTS.FILTERABLE,
        props: {
            mainTitle: "Expenses Test",
            prefix: "$",
            filters: ["Month", "Year"],
            data: {
                Month: props.expenses?.month,
                Year: props.expenses?.year,
            },
            link: null,
        },
    },
    {
        group: GROUPS.APP,
        component: COMPONENTS.LOGS,
        props: {},
    }
    ]
}
